import Vue from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import authentication from "./plugins/authentication"
import store from "./plugins/store"

Vue.config.productionTip = false;
Vue.use(authentication)
Vue.component("pulse-loader", PulseLoader);
// new Vue({
// 	vuetify,
// 	router,
// 	render: h => h(App)
// }).$mount("#app");

const params = {};
const urlParams = new URLSearchParams(window.location.hash ? window.location.hash.substring(1) : window.location.search);

for (const param of urlParams) {
	params[param[0]] = param[1];
}

router.push({ path: window.location.pathname, query: params })

Vue.$keycloak
  .init({ checkLoginIframe: false })
  .then(() => {
    new Vue({
      vuetify,
      router,
      // el: '#app',
      render: h => h(App, { props: { keycloak: Vue.$keycloak } }),
			store
    }).$mount('#app')
})