import Vue from "vue";
import VueRouter from "vue-router";
import CallbackView from "../views/callback-view/callback-view.vue";
import CallbackRedirectView from "../views/callback-redirect/callback-redirect.vue";

Vue.use(VueRouter);

const routes = [

	{
		path: "/callback",
		name: "callbackView",
		component: CallbackView,
		meta: {
			isAuthenticated: false
		}
	},

	{
		path: "/callback-redirect",
		name: "callbackRedirectView",
		component: CallbackRedirectView,
		meta: {
			isAuthenticated: false
		}
	}
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.isAuthenticated) {
		const basePath = window.location.toString()
		if (!Vue.$keycloak.authenticated) {
		if(to){
			Vue.$keycloak.login({ redirectUri: window.location.origin + to.fullPath })
		}
	}else{
		localStorage.setItem("token", Vue.$keycloak.token);
		localStorage.setItem("refresh-token", Vue.$keycloak.refreshToken);
		next()
	}
	}else{
		next()
	}
  })

export default router;
