import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
	state() {
		return {
			brand: null,
			authorizationUrl: null,
			cpf: null,
			code: null,
			idtoken: null,
		};
	},
	mutations: {
		setBrand(state, brand) {
			state.brand = brand;
		},
		setAuthorizationUrl(state, authorizationUrl) {
			state.authorizationUrl = authorizationUrl;
		},
		setCpf(state, cpf) {
			state.cpf = cpf;
		},
		setCode(state, code) {
			state.code = code;
		},
		setIdtoken(state, idtoken) {
			state.idtoken = idtoken;
		}
	}
});

export default store;