import axios from "axios";

export default {

	data: () => ({
	}),
	async mounted(data) {

		let params = {
			state: this.$route.query.state,
			code: localStorage.getItem("code"),
			id_token: localStorage.getItem("idtoken")
		};

		try {

			const response = await axios.post("/api/smart-keys/data-reception/v1/consents/callback", params);

			setTimeout(() => {
				window.location.replace(response.data.url);
			}, 5000);

		} catch (error) {
			console.log(error);
		}

	},
	computed: {
		primaryColor() {
			return this.$vuetify.theme.themes.light.primary;
		}
	}
};