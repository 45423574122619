import axios from "axios";

export default {
	data: () => ({
		callbackCompleted: false,
		applicationName: null,
		applicationLogo: null,
		msg: null
	}),
	async mounted() {
		const params = {};
		const urlParams = new URLSearchParams(window.location.hash ? window.location.hash.substring(1) : window.location.search);
		for (const param of urlParams) {
			params[param[0]] = param[1];
		}

		// const resource = await axios.get(`/open-keys-itp/api/onboard/v1/resources/${params.state}/application`);

		const resource = await axios.get(`/api/smart-keys/auth/v1/resources/${params.state}`);

		this.applicationLogo = resource.data.logo;
		this.applicationName = resource.data.name;

		const kind = resource.data.kind;

		if (kind == "PAYMENT") {

			this.msg = "Estamos processando o seu pagamento."

			try {

				const response = await axios.post("/open-keys-itp/api/payment-initiation/v1/consents/callback", params);

				setTimeout(() => {
					window.location.replace(response.data.url);
				}, 5000);

			} catch (error) {
				console.log(error);
			}
		} else {

			this.msg = "Estamos processando os seus dados."

			try {
				localStorage.setItem("code", params.code);
				localStorage.setItem("idtoken", params.id_token);
				const response = await axios.post("/api/smart-keys/data-reception/v1/consents/callback", params);

				setTimeout(() => {
					// this.$router.push({ path: "/callback-redirect", query: { state: params.state } });
					window.location.replace(response.data.url);
				}, 5000);
			} catch (error) {
				console.log(error);
			}
		}

		this.callbackCompleted = true;

	},
	computed: {
		primaryColor() {
			return this.$vuetify.theme.themes.light.primary;
		}
	}
};
